import React from 'react';
import { MainWrapper, MainTitle } from './home.styles';

const Main: React.FC = () => {
  return (
    <MainWrapper>
          <MainTitle>Rob Rich Official Website </MainTitle>
    </MainWrapper>
  );
};
export default Main;
